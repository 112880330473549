import {
  AddClientToAnalysisListMutation,
  AddClientToAnalysisListMutationVariables,
  ClientCreateMutation,
  ClientCreateMutationVariables,
  ClientQboConnectMutation,
  ClientQboConnectMutationVariables,
  ClientQboUpdateMutation,
  ClientQboUpdateMutationVariables,
  ClientUpdateMutation,
  ClientUpdateMutationVariables
} from '@mutations/client.generated';
import { gql } from '@apollo/client';
import { CLIENT_CARD_FRAGMENT, CLIENT_EDIT_FRAGMENT } from '@fragments/client';
import { VALIDATION_ERROR_FIELDS_FRAGMENT } from '@fragments/general';
import { apolloMutationHookGenerator } from 'utils/hookGenerator';

export const CREATE_CLIENT_M = gql`
  ${VALIDATION_ERROR_FIELDS_FRAGMENT}
  mutation ClientCreate($input: ClientCreateInput!) {
    clientCreate(input: $input) {
      clientMutationId
      errors {
        genericError
        validationErrors {
          ...ValidationErrorFields
        }
      }
      client {
        id
      }
    }
  }
`;

export const useClientCreateM = apolloMutationHookGenerator<ClientCreateMutation, ClientCreateMutationVariables>(
  CREATE_CLIENT_M,
  {
    notifyOnNetworkStatusChange: true
  }
);

export const CONNECT_QBO_CLIENT = gql`
  ${VALIDATION_ERROR_FIELDS_FRAGMENT}
  mutation ClientQboConnect($input: ClientQboConnectInput!) {
    clientQboConnect(input: $input) {
      clientMutationId
      errors {
        genericError
        validationErrors {
          ...ValidationErrorFields
        }
      }
      client {
        id
      }
    }
  }
`;

export const useConnectQboClientM = apolloMutationHookGenerator<
  ClientQboConnectMutation,
  ClientQboConnectMutationVariables
>(CONNECT_QBO_CLIENT, {
  notifyOnNetworkStatusChange: true
});

export const UPDATE_QBO_CLIENT = gql`
  ${VALIDATION_ERROR_FIELDS_FRAGMENT}
  mutation ClientQboUpdate($input: ClientQboUpdateInput!) {
    clientQboUpdate(input: $input) {
      errors {
        genericError
        validationErrors {
          ...ValidationErrorFields
        }
      }
      success
    }
  }
`;

export const useClientQboUpdateM = apolloMutationHookGenerator<
  ClientQboUpdateMutation,
  ClientQboUpdateMutationVariables
>(UPDATE_QBO_CLIENT, {
  notifyOnNetworkStatusChange: true
});

export const UPDATE_CLIENT_M = gql`
  ${VALIDATION_ERROR_FIELDS_FRAGMENT}
  ${CLIENT_EDIT_FRAGMENT}
  mutation ClientUpdate($input: ClientUpdateInput!) {
    clientUpdate(input: $input) {
      clientMutationId
      errors {
        genericError
        validationErrors {
          ...ValidationErrorFields
        }
      }
      client {
        ...ClientEdit
      }
    }
  }
`;

export const useClientUpdateM = apolloMutationHookGenerator<ClientUpdateMutation, ClientUpdateMutationVariables>(
  UPDATE_CLIENT_M,
  {
    notifyOnNetworkStatusChange: true
  }
);

export const ADD_CLIENT_TO_ANALYSIS_LIST_M = gql`
  ${VALIDATION_ERROR_FIELDS_FRAGMENT}
  mutation AddClientToAnalysisList($input: AddClientToAnalysisListInput!) {
    addClientToAnalysisList(input: $input) {
      clientMutationId
      errors {
        genericError
        validationErrors {
          ...ValidationErrorFields
        }
      }
    }
  }
`;

export const useAddClientToAnalysisListM = apolloMutationHookGenerator<
  AddClientToAnalysisListMutation,
  AddClientToAnalysisListMutationVariables
>(ADD_CLIENT_TO_ANALYSIS_LIST_M, {
  notifyOnNetworkStatusChange: true
});
